// TODO Place all constants related to form validation in this file (regular expressions, minimum/maximum field lengths)

export const userGeneralRegexp = /^([\p{L} .@_-])+$/gu

export const userPasswordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/g

// creating a user: fields name, surname, patronymic
export const userCommonRegexp = /^([A-Za-zА-Яа-яЁё _\-.@])+$/g

// creating a user: login field
export const userLoginRegexp = /^([A-Za-z0-9_\-.@])+$/g

// creating a user: email field
export const userEmailRegexp = /^([A-Za-z0-9._-])+@([A-Za-z0-9._-])+\.([A-Za-z]{2,63})$/g

// creating a user: position field
export const userPositionRegexp = /^([\p{L} .@_\-№\d\\/])+$/gu

// organization requisites: alias field
export const aliasRegexp = /^([A-Za-z0-9_-])+$/g

// organization requisites: fields name, name_short
export const orgNameRegexp = /^([\p{L}\p{N} ()"'«».,_-])+$/gu

// organization requisites: OGRN field
export const orgOgrnRegexp = /^([\p{L}\p{N}-])+$/gu

// organization requisites: type
export const orgTypeRegexp = /^[ЗО]?[АО]О\s/g

// organization requisites: quotes
export const quotesRegexp = /["«»'’]/g

export const kppRegexp = /^\d{9}$/

export const docTypeKeyRegexp = /^[A-Za-z0-9_\-.]+$/g

export const fieldKeyRegexp = /^[A-Za-z0-9._-]+$/g

export const handbookKeyRegexp = /^[A-Za-z0-9_\-.@]+$/g

export const handbookNameRegexp = /^[A-Za-z0-9_-]+$/g

export const handbookValueRegexp = /^([\p{L}\p{N}\p{S}\p{P}\p{Z}])+$/gu

// custom filters: filter name
export const userFiltersTitleRegexp = /^([\p{L}\p{N} ()"'«».,_-])+$/gu
export const userFiltersTitleMaxLength = 20

export const groupNameLength = {
  MIN: 4,
  MAX: 2000
}

export const groupNameRegexp = /^[\p{L}\p{N} ()"'«».,_-]+$/gu

export const routeNameLength = {
  MAX: 2000
}

export const routeNameRegexp = /^([\p{L}\p{N}\p{S}\p{P}\p{Z}])+$/gu

export const routeStageUserNameLength = {
  MAX: 2000
}
export const routeStageUserNameRegexp = /^[\p{L}\p{N} ]+$/gu

export const constraintCodeRegexp = /^[A-Za-z0-9._-]+$/g

export const constraintCodeLength = {
  MAX: 40,
  MIN: 1
}

export const constraintErrorMessageLength = {
  MAX: 2000,
  MIN: 1
}

export const constraintNameLength = {
  MAX: 2000,
  MIN: 1
}

export const constraintDocumentTypeKeysLength = {
  MIN: 1
}

export const constraintFieldKeysLength = {
  MIN: 1
}

export const snilsRegexp = /^\d{11}$/

export const addressRegexp = /^[\p{L}\p{N} \\()"’'«».,_-]+$/gu

export const innRegexp = /^(\d{10}|\d{12})$/

export const passportRegexp = /^[\p{L}\p{N} .,]+$/gu

export const foreignAddressRegexp = /^([\p{L}\p{N}\p{S}\p{P}\p{Z}])+$/gu

export const foreignCountryRegexp = /^([\p{L} ()’'.,-])+$/gu

export const phoneRegexp = /^\+?[ -]*(\d[ -]*){11,15}$/

export const contractorAddrTextRegexp = /^([\p{L}\p{N}\p{S}\p{P}\p{Z}])+$/gu

export const contractorInnRegexp = /^(\d{10}|\d{12})$/

export const contractorKppRegexp = /^\d{9}$/

export const contractorNameRegexp = /^([\p{L}\p{N} ()"'«».,_-])+$/gu

export const contractorOgrnRegexp = /^\d{13}$/

export const contractorForeignOgrnRegexp = /^([\p{L}\p{N}-])+$/gu

export const numberRegexp = /^-?(\d)?/

export const passportLength = {
  MIN: 1,
  MAX: 100
}

export const addressLength = {
  MIN: 1,
  MAX: 255
}

export const addressLengthAlt = {
  MIN: 4,
  MAX: 2000
}

export const allKeysRegexp = /^[\p{L}\p{N}\p{S}\p{P}\p{Z}]+$/gu

export const documentTypeGroupRegexp = /^([\p{L}\p{N} ()"'«».,_-])+$/gu

export const documentTypeGroupLength = {
  MIN: 4,
  MAX: 100
}

export const divisionNameRegexp = /^[\p{L}\p{N} ()"'«».,_-]+$/gu

export const documentTypeAccessGroupNameLength = {
  MIN: 1,
  MAX: 2000
}

export const additionalInfoRegexp = /^([\p{L}\p{N}/\\№ .@_-]){1,2000}$/gu

export const additionalInfoLength = {
  MIN: 1,
  MAX: 2000
}

export const autoDocumentNumberLength = {
  MIN: 5,
  MAX: 9
}

import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'

import { actions as userActions } from '@store/modules/user/actions'

import { BrowserStorage, RouteName, Section } from '@const/consts'
import { toggleRootModeInUserSettings } from '@utils/utils'

import TokensService from '@services/tokensService'

import { AllServiceParams, IApplicationState, IUserOrganization } from '@store/types/commonTypes'
import { IRouteMainParams as IProps } from '@common/Routing/components/RouteWithSubRoutes/types'

export const PrivateRoute:FC<IProps> = (props) => {
  const { component: ComposedComponent, ...rest } = props

  const dispatch = useDispatch<any>()
  const {
    activeOrganization: {
      alias,
      oguid
    },
    profile: {
      oguid: userOguid,
      orgs: organizations
    }
  } = useSelector((store: IApplicationState) => store.user)
  const {
    isRootMode,
    justDeletedOrgOguid
  } = useSelector((store: IApplicationState) => store.utils)

  const [isTokenRefreshing, setTokenRefreshing] = useState(false)

  const location = useLocation<AllServiceParams>()

  const getAvailableOrganization = (
    paramOrg: string,
    organizations: IUserOrganization[]
  ): IUserOrganization | undefined => {
    return (
      organizations.find((organization) => organization.alias === paramOrg) ??
      organizations.find((organization) => organization.oguid === paramOrg)
    )
  }

  const changeOrganization = (organization: IUserOrganization) => {
    dispatch(userActions.setActiveOrganization(organization))
  }

  const handleRender = (renderProps: RouteComponentProps<AllServiceParams>): ReactElement => {
    const authorized = TokensService.checkAccessToken()

    const {
      location: { pathname, search }
    } = renderProps

    const currentLocation = `${pathname}${search}`

    if (!authorized && !isTokenRefreshing) {
      setTokenRefreshing(true)

      const refreshToken = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_REFRESH, userOguid)

      if (!refreshToken) {
        return (
          <Redirect
            to={{
              pathname: RouteName.SIGN_IN,
              state: { from: currentLocation }
            }}
          />
        )
      }

      TokensService.refresh(refreshToken)
        .catch(() => null)
        .finally(() => {
          setTokenRefreshing(false)
        })
    }

    if (pathname === RouteName.DEFAULT && isRootMode) {
      return (
        <Redirect
          to={{
            pathname: Section.DOCUMENTS_IN_PROGRESS,
            state: { from: currentLocation }
          }}
        />
      )
    }

    if (justDeletedOrgOguid === oguid) return <ComposedComponent {...renderProps} />

    return <ComposedComponent {...renderProps} />
  }

  useEffect(() => {
    const { pathname } = location

    const paramOrg = pathname.split('/')[1]
    const availableOrganization = getAvailableOrganization(paramOrg, organizations)

    if (availableOrganization) {
      if (isRootMode) {
        toggleRootModeInUserSettings(false)
        changeOrganization(availableOrganization)
      } else if (paramOrg !== alias && paramOrg !== oguid) {
        changeOrganization(availableOrganization)
      }
    } else toggleRootModeInUserSettings(true)
  }, [location])

  return <Route {...rest} render={handleRender} />
}

export default PrivateRoute

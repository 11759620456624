import cn from 'classnames'
import React, { FC, ReactElement, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
  DropdownList,
  Ellipsis,
  ILibDropdownItem,
  ILibHorizontalPosition,
  IconCog,
  IconSignOut,
  IconUser,
  Popover,
  getSurnameWithInitials
} from '@infologistics/frontend-libraries'

import { RouteName, UserMenuItem } from '@const/consts'
import { createUrl } from '@utils/utils'
import { logout } from '@store/modules/root/actions'
import { IApplicationState } from '@store/types/commonTypes'
import { IUserMenuProps as IProps } from './types'

import styles from './UserMenu.module.css'

const UserMenu: FC<IProps> = ({ history, match }: IProps) => {
  const { t } = useTranslation(['common', 'profile'])

  const { profile } = useSelector((state: IApplicationState) => state.user)
  const dispatch = useDispatch<any>()

  const [popoverOpened, setPopoverOpened] = useState<boolean>(false)

  const closePopover = (isOpenFromPopover: boolean): void => {
    !isOpenFromPopover && setPopoverOpened(false)
  }

  const togglePopover = (): void => setPopoverOpened(!popoverOpened)

  const getPopoverBtn = (): ReactElement => {
    return (
      <div onClick={togglePopover} className={cn(styles.btn_wrapper, 'd-flex align-items-center')}>
        <IconUser classes={cn(styles.btn_icon, styles.icon_margin)}/>
        <Ellipsis>{getSurnameWithInitials(profile)}</Ellipsis>
      </div>
    )
  }

  const getDropdownItems = (): ILibDropdownItem[] => {
    return [
      {
        name: UserMenuItem.PROFILE,
        element: (
          <span className='d-flex align-items-center text-nowrap'>
            <IconCog classes={cn(styles.icon_margin, 'flex-shrink-0')}/>
            {t('profile:profileSettings')}
          </span>
        )
      },
      {
        name: UserMenuItem.LOGOUT,
        element: (
          <span className='d-flex align-items-center text-nowrap'>
            <IconSignOut classes={cn(styles.icon_margin, 'flex-shrink-0')}/>
            {t('common:signout')}
          </span>
        )
      }
    ]
  }

  const handleSelect = ({ name }: ILibDropdownItem): void => {
    const url = name === UserMenuItem.PROFILE
      ? createUrl(match, RouteName.PROFILE)
      : RouteName.SIGN_IN

      if (name === UserMenuItem.LOGOUT) {
        dispatch(logout())
      }

      history.push(url)
      setPopoverOpened(false)
  }

  return (
    <Popover
      buttonComponent={getPopoverBtn()}
      containerClasses={styles.popover_container}
      popoverClasses={styles.popover}
      withoutArrow
      allowDropdownFlip
      horizontalPosition={ILibHorizontalPosition.RIGHT}
      parentManagement={{
        isOpenFromParent: popoverOpened,
        callbackForClose: closePopover
      }}
    >
      <DropdownList
        dropdownItems={getDropdownItems()}
        onSelect={handleSelect}
        itemClasses='p-2'
      />
    </Popover>
  )
}

export default withRouter(UserMenu)

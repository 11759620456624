import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { getOrganizationInfo, createPath } from '@utils/utils'

import TokensService from '@services/tokensService';

import { AllLinkProps as IProps } from './types'

const OrganizationLink: FC<IProps> = (props) => {
  const {
    to,
    alias,
    oguid,
    dispatch,
    isRootMode,
    ...rest
  } = props

  const authorized = TokensService.checkAccessToken()

  if (!authorized || isRootMode) return <Link to={to} {...rest} />

  const organization = getOrganizationInfo(alias, oguid)

  return <Link to={createPath(organization, to)} {...rest} />
}

export default OrganizationLink

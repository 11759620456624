import { Action, ActionCreator } from 'redux'

import {
  AccessMassiveActionPage,
  IActionResetError404,
  IActionResetError500,
  IActionSetAsideWidth,
  IActionSetExistingUser,
  IActionSetLanguage,
  IActionSetMassiveActionPage,
  IActionSetMissingFields,
  IActionSetMissingType,
  IActionSetMissingTypeFields,
  IActionToggleFetchFiltersData,
  IActionSetTheme,
  IActionToggleMenus,
  IActionToggleMessages,
  IActionToggleNotices,
  IActionToggleNavMenu,
  IActionToggleRootMode,
  IMissingTypeFields,
  Themes,
  UtilsActionTypes,
  IActionSidebarTabsIsOpen,
  IActionSetInstance,
  IActionSetJustDeletedOrg,
  IActionSetLastLocation
} from './types'
import { Instance } from '@const/consts'
import { Nullable } from '@store/types/commonTypes'

export const onToggleBreadcrumbs: ActionCreator<Action> = (): Action => ({
  type: UtilsActionTypes.TOGGLE_BREADCRUMBS
})

export const toggleMenus: ActionCreator<Action> = (data: boolean): IActionToggleMenus => ({
  payload: data,
  type: UtilsActionTypes.TOGGLE_MENUS
})

export const toggleNavMenu: ActionCreator<Action> = (data: boolean): IActionToggleNavMenu => ({
  payload: data,
  type: UtilsActionTypes.TOGGLE_NAV_MENU
})

export const toggleRootMode: ActionCreator<Action> = (data: boolean): IActionToggleRootMode => ({
  payload: data,
  type: UtilsActionTypes.TOGGLE_ROOT_MODE
})

export const setError404: ActionCreator<Action> = (): Action => ({
  type: UtilsActionTypes.SET_ERROR404
})

export const setError500: ActionCreator<Action> = (): Action => ({
  type: UtilsActionTypes.SET_ERROR500
})

export const setLanguage: ActionCreator<Action> = (language: string): IActionSetLanguage => ({
  payload: language,
  type: UtilsActionTypes.SET_LANGUAGE
})

export const setMassiveActionPage: ActionCreator<Action> = (
  payload: AccessMassiveActionPage
): IActionSetMassiveActionPage => ({
  payload,
  type: UtilsActionTypes.SET_MASSIVE_ACTION_PAGE
})

export const setMissingFields: ActionCreator<Action> = (fields: string[]): IActionSetMissingFields => ({
  payload: fields,
  type: UtilsActionTypes.SET_MISSING_FIELDS
})

export const setMissingType: ActionCreator<Action> = (type: string): IActionSetMissingType => ({
  payload: type,
  type: UtilsActionTypes.SET_MISSING_TYPE
})

export const setMissingTypeFields: ActionCreator<Action> = (payload: IMissingTypeFields): IActionSetMissingTypeFields => ({
  payload,
  type: UtilsActionTypes.SET_MISSING_TYPE_FIELDS
})

export const setTheme: ActionCreator<Action> = (payload: Themes): IActionSetTheme => ({
  payload,
  type: UtilsActionTypes.SET_THEME
})

export const resetError404: IActionResetError404 = {
  type: UtilsActionTypes.RESET_ERROR404
}

export const resetError500: IActionResetError500 = {
  type: UtilsActionTypes.RESET_ERROR500
}

export const setExistingUserOguid: ActionCreator<Action> = (oguid: string): IActionSetExistingUser => ({
  payload: oguid,
  type: UtilsActionTypes.SET_EXISTING_USER
})

export const toggleMessages: ActionCreator<Action> = (payload: boolean): IActionToggleMessages => ({
  payload,
  type: UtilsActionTypes.TOGGLE_MESSAGES
})

export const toggleNotices: ActionCreator<Action> = (payload: boolean): IActionToggleNotices => ({
  payload,
  type: UtilsActionTypes.TOGGLE_NOTICES
})

export const setAsideWidth: ActionCreator<Action> = (payload: string): IActionSetAsideWidth => ({
  payload,
  type: UtilsActionTypes.SET_ASIDE_WIDTH
})

export const toggleFetchFiltersData: ActionCreator<Action> = (payload: boolean): IActionToggleFetchFiltersData => ({
  payload,
  type: UtilsActionTypes.TOGGLE_FETCH_FILTERS_DATA
})

export const setSidebarTabsIsOpen: ActionCreator<Action> = (payload: boolean): IActionSidebarTabsIsOpen => ({
  payload,
  type: UtilsActionTypes.SIDEBAR_TABS_IS_OPEN
})

export const setInstance: ActionCreator<Action> = (instance: Instance): IActionSetInstance => ({
  payload: instance,
  type: UtilsActionTypes.SET_INSTANCE
})

export const setJustDeletedOrg: ActionCreator<Action> = (payload: Nullable<string>): IActionSetJustDeletedOrg => ({
  payload,
  type: UtilsActionTypes.SET_JUST_DELETED_ORG
})

export const setLastLocation: ActionCreator<Action> = (payload: string): IActionSetLastLocation => ({
  payload,
  type: UtilsActionTypes.SET_LAST_LOCATION
})

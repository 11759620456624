import { Actions, IUserState, UserActionTypes } from './types'

export const initialState: IUserState = {
  phoneAuthId: '',
  activeOrganization: {
    alias: '',
    inn: '',
    kpp: '',
    name: '',
    nameShort: '',
    oguid: '',
    ogrn: null
  },
  extendedSigner: null,
  profile: {
    email: '',
    login: '',
    name: '',
    oguid: '',
    orgs: [],
    patronymic: '',
    phone: '',
    surname: '',
    locale: '',
    timeZone: '',
    isPhoneConfirmed: false
  },
  role: '',
  isReadonly: false,
  isExchangeParticipant: false,
  isFlowFunctionalityEnabled: false,
  position: null,
  settings: null,
  isShowPhoneInput: true,
  logoOrg: null
}

export default function userReducer(state: IUserState = initialState, action: Actions): IUserState {
  switch (action.type) {
    case UserActionTypes.SET_ACTIVE_ORGANIZATION:
      return { ...state, activeOrganization: { ...action.payload } }

    case UserActionTypes.SET_PROFILE:
      return {
        ...state,
        profile: {
            ...action.payload,
            phone: action.payload.phone ? `+${action.payload.phone}` : null
          }
      }

    case UserActionTypes.SET_USER_ORGANIZATIONS:
      return {
        ...state,
        profile: {
          ...state.profile,
          orgs: action.payload
        }
      }

    case UserActionTypes.SET_USER_EXTENDED_INFO:
      return {
        ...state,
        ...action.payload
      }

    case UserActionTypes.SET_EXCHANGE_PARTICIPANT:
      return {
        ...state,
        isExchangeParticipant: action.payload
      }

    case UserActionTypes.SET_FLOW_FUNCTIONALITY:
      return {
        ...state,
        isFlowFunctionalityEnabled: action.payload
      }

    case UserActionTypes.SET_USER_SETTINGS:
      return {
        ...state,
        settings: action.payload
      }

    case UserActionTypes.SET_PHONE_AUTH_ID:
      return {
        ...state,
        phoneAuthId: action.payload
      }

    case UserActionTypes.IS_SHOW_PHONE_INPUT:
      return {
        ...state,
        isShowPhoneInput: action.payload
      }

    case UserActionTypes.SET_LOGO_ORG:
      return {
        ...state,
        logoOrg: action.payload
      }

    default:
      return state
  }
}

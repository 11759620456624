import { connect } from 'react-redux'

import { LayoutRouter } from '@common/Layout'

import { getDocumentTypes } from '@store/modules/metadata/actions'
import { getAllBadges, getBadges, getBadgesNotices } from '@store/modules/navigation/actions'
import { setInstance, toggleFetchFiltersData } from '@store/modules/utils/actions'

import { Instance } from '@const/consts'
import { IApplicationState } from '@store/types/commonTypes'
import {
  ILayoutRouterPropsFromDispatch as IPropsFromDispatch,
  ILayoutRouterPropsFromState as IPropsFromState
} from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isError404: state.utils.isError404,
  isError500: state.utils.isError500,
  userRole: state.user.role,
  userOguid: state.user.profile.oguid,
  isRootMode: state.utils.isRootMode
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetAllBadges: () => dispatch(getAllBadges()),
  onGetBadges: () => dispatch(getBadges()),
  onGetBadgesNotices: () => dispatch(getBadgesNotices()),
  onGetDocumentTypes: () => dispatch(getDocumentTypes()),
  onToggleFetchFiltersData: (value: boolean) => dispatch(toggleFetchFiltersData(value)),
  onSetInstance: (instance: Instance | string) => dispatch(setInstance(instance))
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutRouter)

import axios, { AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode'
import mem from 'mem'
import { CookieSetOptions } from 'universal-cookie'
import { DateTime } from 'luxon'

import store from '@store/configureStore'

import {
  BASE_URL,
  BooleanValue,
  BrowserStorage,
  WindowEventType,
  MAX_TOKEN_CASH_AGE,
  SessionStorageKeysForSaveBetweenSessions, MILLISECONDS_IN_SECOND, SuccessCode
} from '@const/consts'
import urls from '@const/urls'

import cookies from '@utils/cookies'

import { ITokensFromAuth, ITokens, ITokenTabs, ITokenToRefresh } from '@store/modules/user/types'
import { Nullable } from '@store/types/commonTypes'

const cookiesPath = { path: '/' }

class TokensService {
  // Saving
  // - "Remember me" flag
  public setIsRememberToCookies = (flag: string, options: CookieSetOptions): void => cookies.set(BrowserStorage.TOKEN_IS_REMEMBER, flag, options)

  public setIsRememberToSession = (flag: string): void => sessionStorage.setItem(BrowserStorage.TOKEN_IS_REMEMBER, flag)

  // - user
  public setUserToCookies = (user: string, options: CookieSetOptions): void => cookies.set(BrowserStorage.TOKEN_USER, user, options)

  public setUserToSession = (user: string): void => sessionStorage.setItem(BrowserStorage.TOKEN_USER, user)

  // - hash for browser tab
  public setTab = (): void => {
    const isTab = this.getTab()

    // If the tab does not have a hash, then
    if (!isTab) {
      // - hash generation
      const tab = `${BrowserStorage.TOKEN_TAB}_${+new Date()}`

      // - saving to sessionStorage
      sessionStorage.setItem(BrowserStorage.TOKEN_TAB, tab)

      // - adding tab to the list of opened browser tabs with the application in localStorage
      const tabs = this.getTabs()
      tabs[tab] = true

      this.setTabs(tabs)
    }
  }

  // - currently opened tabs with the application
  public setTabs = (tabs: ITokenTabs): void => localStorage.setItem(BrowserStorage.TOKEN_TABS, JSON.stringify(tabs))

  // - token in sessionStorage
  public setTokenToSession = (tokenKey: string, token: string): void => sessionStorage.setItem(tokenKey, token)

  // - token in cookies
  public setTokenToCookies = (tokenKey: string, user: string, token: string, options: CookieSetOptions): void =>
    cookies.set(this.getTokenAffix(tokenKey, user), token, options)

  // - full cookies
  public setCookies = ({ access, file, refresh }: ITokensFromAuth, isRemember: string): void => {
    // Determining a user based on token data, for example, a refresh token
    const user = this.decodeUser(refresh.value)

    // Getting cookie options based on token
    const accessOptions = this.getCookiesOptions(access.value)
    const fileOptions = this.getCookiesOptions(file.value)
    const refreshOptions = this.getCookiesOptions(refresh.value)

    // Setting:
    // -  t_is_remember and t_user flags
    this.setIsRememberToCookies(isRemember, refreshOptions)
    this.setUserToCookies(user, refreshOptions)

    // - tokens
    this.setTokenToCookies(BrowserStorage.TOKEN_ACCESS, user, access.value, accessOptions)
    this.setTokenToCookies(BrowserStorage.TOKEN_FILE, user, file.value, fileOptions)
    this.setTokenToCookies(BrowserStorage.TOKEN_REFRESH, user, refresh.value, refreshOptions)
  }

  public setSession = (isRemember: string): void => {
    const userFromService = this.getUserFromCookies()

    const accessToken = this.getTokenFromCookies(BrowserStorage.TOKEN_ACCESS, userFromService)
    const fileToken = this.getTokenFromCookies(BrowserStorage.TOKEN_FILE, userFromService)
    const refreshToken = this.getTokenFromCookies(BrowserStorage.TOKEN_REFRESH, userFromService)

    if (isRemember && userFromService) {
      this.setIsRememberToSession(isRemember)
      this.setUserToSession(userFromService)

      if (accessToken) this.setTokenToSession(this.getTokenAffix(BrowserStorage.TOKEN_ACCESS, userFromService), accessToken)

      if (fileToken) this.setTokenToSession(this.getTokenAffix(BrowserStorage.TOKEN_FILE, userFromService), fileToken)

      if (refreshToken) this.setTokenToSession(this.getTokenAffix(BrowserStorage.TOKEN_REFRESH, userFromService), refreshToken)
    }
  }

  // Getting
  // - "Remember me" flag
  public getIsRememberFromCookies = (): Nullable<string> => cookies.get(BrowserStorage.TOKEN_IS_REMEMBER) ?? null

  public getIsRememberFromSession = (): Nullable<string> => sessionStorage.getItem(BrowserStorage.TOKEN_IS_REMEMBER)

  // - user
  public getUserFromCookies = (): Nullable<string> => cookies.get(BrowserStorage.TOKEN_USER) ?? null

  public getUserFromSession = (): Nullable<string> => sessionStorage.getItem(BrowserStorage.TOKEN_USER)

  // - tab hash
  public  getTab = (): Nullable<string> => sessionStorage.getItem(BrowserStorage.TOKEN_TAB)

  // - current opened tabs with the application
  public getTabs = (): ITokenTabs => JSON.parse(localStorage.getItem(BrowserStorage.TOKEN_TABS) ?? '{}')

  // - token by key
  public getTokenFromCookies = (tokenKey: string, user: Nullable<string>): Nullable<string> => {
    if (!user) return null

    return cookies.get(this.getTokenAffix(tokenKey, user)) ?? null
  }

  public getTokenFromSession = (tokenKey: string, user: string): Nullable<string> =>
    sessionStorage.getItem(this.getTokenAffix(tokenKey, user))

  // - both tokens to initialize the application
  public getTokensToStartup = (): ITokens => {
    let userFromService = this.getUserFromCookies()

    let accessToken: Nullable<string> = null
    let refreshToken: Nullable<string> = null

    // If the user exists, then search for tokens in cookies
    if (userFromService) {
      accessToken = this.getTokenFromCookies(BrowserStorage.TOKEN_ACCESS, userFromService)
      refreshToken = this.getTokenFromCookies(BrowserStorage.TOKEN_REFRESH, userFromService)
    }

    // If not, then:
    if (!userFromService) {
      // - checking user and the "Remember me" flag in sessionStorage
      userFromService = this.getUserFromSession()
      const isRemember = this.getIsRememberFromSession()

      // If they exist, then:
      if (userFromService && isRemember) {
        // - getting tokens from sessionStorage
        accessToken = this.getTokenFromSession(BrowserStorage.TOKEN_ACCESS, userFromService)
        refreshToken = this.getTokenFromSession(BrowserStorage.TOKEN_REFRESH, userFromService)

        const fileToken = this.getTokenFromSession(BrowserStorage.TOKEN_FILE, userFromService)

        // - transferring data to cookies
        if (refreshToken) {
          const refreshOptions = this.getCookiesOptions(refreshToken)

          this.setIsRememberToCookies(isRemember, refreshOptions)
          this.setUserToCookies(userFromService, refreshOptions)

          this.setTokenToCookies(BrowserStorage.TOKEN_REFRESH, userFromService, refreshToken, refreshOptions)
        }

        if (accessToken) {
          const accessOptions = this.getCookiesOptions(accessToken)

          this.setTokenToCookies(BrowserStorage.TOKEN_ACCESS, userFromService, accessToken, accessOptions)
        }

        if (fileToken) {
          const fileOptions = this.getCookiesOptions(fileToken)

          this.setTokenToCookies(BrowserStorage.TOKEN_FILE, userFromService, fileToken, fileOptions)
        }

        // - clearing sessionStorage
        this.clearSession()
      }
    }

    return { refreshToken, accessToken }
  }

  // Checking
  // - presence of accessToken in cookies and its relevance
  public checkAccessToken = (): boolean => {
    const userFromService = this.getUserFromCookies()

    return !!this.getTokenFromCookies(BrowserStorage.TOKEN_ACCESS, userFromService)
  }

  // Refreshing tokens
  public refresh = async (refreshToken: Nullable<string>, isGenerateNewRefresh = false): Promise<void> => {
    const isRemember = this.getIsRememberFromCookies()

    try {
      // If "Remember me" flag and refresh token exist, then
      if (isRemember && refreshToken) {
        // - forming a request
        const url = urls.users.tokenRefresh

        const dataToServer: ITokenToRefresh = {
          value: refreshToken,
          generateNewToken: isGenerateNewRefresh
        }

        // - request to receive a new pair of tokens
        const response: AxiosResponse<ITokensFromAuth> = await axios.create({
          baseURL: BASE_URL,
          responseType: 'json'
        }).post(url, dataToServer)

        const { status, data } = response

        // - saving a new pair of tokens
        if (SuccessCode.POST.includes(status)) {
          this.setCookies(data, isRemember)
        }
      }
    } catch {
      const { oguid: user } = store.getState().user.profile

      // If for some reason the token refresh fails, for example the refresh token is invalid,
      // then after deleting cookies
      this.clearCookies(user)
      // the authorization form will be displayed
      document.location.reload()
    }
  }

  public memoized = mem(this.refresh, { maxAge: MAX_TOKEN_CASH_AGE })

  // Cleanup:
  // - data from cookies
  public clearCookies = (user: string): void => {
    cookies.remove(BrowserStorage.TOKEN_IS_REMEMBER, cookiesPath)
    cookies.remove(BrowserStorage.TOKEN_USER, cookiesPath)

    cookies.remove(this.getTokenAffix(BrowserStorage.TOKEN_ACCESS, user), cookiesPath)
    cookies.remove(this.getTokenAffix(BrowserStorage.TOKEN_FILE, user), cookiesPath)
    cookies.remove(this.getTokenAffix(BrowserStorage.TOKEN_REFRESH, user), cookiesPath)
  }

  // - data from sessionStorage except the hash for the browser tab
  public clearSession = (): void => {
    const keysToClear = Object.keys(sessionStorage).filter(
      (key) => !(SessionStorageKeysForSaveBetweenSessions).includes(key)
    )

    keysToClear.forEach((key) => {
      sessionStorage.removeItem(key)
    })
  }

  // - hash for the browser tab
  private readonly clearTab = (): void => sessionStorage.removeItem(BrowserStorage.TOKEN_TAB)

  // - data when updating / closing a tab or browser
  public clearBeforeUnloadTab = (): void => {
    window.addEventListener(WindowEventType.BEFORE_UNLOAD, this.handleBeforeUnloadTab)
  }

  private readonly handleBeforeUnloadTab = (evt: BeforeUnloadEvent): void => {
    const tab = this.getTab()
    const tabs = this.getTabs()

    if (tab && tabs[tab]) {
      delete tabs[tab]

      this.setTabs(tabs)
      this.clearTab()

      // If the "Remember me" flag is not set
      const isRemember = this.getIsRememberFromCookies()
      const isSession = isRemember === BooleanValue.FALSE

      // and this tab is the last (only) opened with the application, then
      const isSingleTab = !Object.keys(tabs).length

      if (isRemember && isSession && isSingleTab) {
        const { oguid: user } = store.getState().user.profile

        // - transferring data to sessionStorage in case the tab is not closed, but updated
        this.setSession(isRemember)

        // - clearing data in cookies
        this.clearCookies(user)
      }
    }
  }

  // Additional receiving
  // - options for cookies
  private readonly getCookiesOptions = (token: string): CookieSetOptions => {
    const options: CookieSetOptions = {
      ...cookiesPath
    }

    // The cookie lifetime is set for the session based on the value from the token itself
    const decodedToken: any = jwtDecode(token)
    const expireToken = decodedToken.exp * MILLISECONDS_IN_SECOND

    options.expires = new Date(expireToken)

    return options
  }

  // - token affix
  private readonly getTokenAffix = (tokenKey: string, user: string): string => `${tokenKey}_${user}`

  // - user from token
  private readonly decodeUser = (token: string): string => {
    const decodedToken: any = jwtDecode(token)

    return decodedToken.oGuid
  }

  // Additional refresh token check to determine whether it is necessary to generate it
  public isGenerateNewRefresh = (refreshToken: string): boolean => {
    const decodedToken: any = jwtDecode(refreshToken)
    const expireDate = decodedToken.exp * MILLISECONDS_IN_SECOND

    // - if the token's validity period is already less than 30 days (half the period), then the request will be formed with the requirement to generate a new refresh token
    return expireDate <= DateTime.now().plus({ day: 30 }).toMillis()
  }
}

export default new TokensService()

import { Actions, IUtilsState, UtilsActionTypes } from './types'
import { BrowserStorage, Instance, Languages, lookupLocalStorage } from '@const/consts'

export const initialState: IUtilsState = {
  asideWidth: window.getComputedStyle(document.documentElement).getPropertyValue('--aside-width'),
  existingUserOguid: '',
  isBreadcrumbsShown: true,
  isError404: false,
  isError500: false,
  isRootMode: false,
  isShownMenus: true,
  isShownNavMenu: true,
  language: window.localStorage.getItem(lookupLocalStorage) ?? Languages.RU,
  massiveActionPage: 'tasks',
  missingFields: [],
  missingTypeFields: {},
  missingTypes: [],
  themeClass: 'theme-default',
  areMessagesShown: false,
  areNoticesShown: false,
  toggleFetchFiltersData: true,
  sidebarTabsIsOpen: false,
  instance: window.localStorage.getItem(BrowserStorage.INSTANCE) as Instance ?? Instance.GLOBAL,
  justDeletedOrgOguid: null,
  lastLocation: ''
}

export default function utilsReducer(state = initialState, action: Actions): IUtilsState {
  switch (action.type) {
    case UtilsActionTypes.SET_ASIDE_WIDTH:
      return {
        ...state,
        asideWidth: action.payload
      }
    case UtilsActionTypes.TOGGLE_BREADCRUMBS:
      return {
        ...state,
        isBreadcrumbsShown: !state.isBreadcrumbsShown
      }
    case UtilsActionTypes.TOGGLE_MENUS:
      return {
        ...state,
        isShownMenus: action.payload
      }
    case UtilsActionTypes.TOGGLE_NAV_MENU:
      return {
        ...state,
        isShownNavMenu: action.payload
      }
    case UtilsActionTypes.TOGGLE_ROOT_MODE:
      return {
        ...state,
        isRootMode: action.payload
      }
    case UtilsActionTypes.SET_ERROR404:
      return {
        ...state,
        isError404: true
      }
    case UtilsActionTypes.RESET_ERROR404:
      return {
        ...state,
        isError404: false
      }
    case UtilsActionTypes.SET_ERROR500:
      return {
        ...state,
        isError500: true
      }
    case UtilsActionTypes.RESET_ERROR500:
      return {
        ...state,
        isError500: false
      }
    case UtilsActionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      }
    case UtilsActionTypes.SET_MASSIVE_ACTION_PAGE:
      return {
        ...state,
        massiveActionPage: action.payload
      }
    case UtilsActionTypes.SET_THEME:
      return {
        ...state,
        themeClass: action.payload
      }
    case UtilsActionTypes.SET_EXISTING_USER:
      return {
        ...state,
        existingUserOguid: action.payload
      }
    case UtilsActionTypes.SET_MISSING_FIELDS:
      return {
        ...state,
        missingFields: [...state.missingFields, ...action.payload]
      }
    case UtilsActionTypes.SET_MISSING_TYPE:
      return {
        ...state,
        missingTypes: [...state.missingTypes, action.payload]
      }
    case UtilsActionTypes.SET_MISSING_TYPE_FIELDS:
      return {
        ...state,
        missingTypeFields: {
          ...state.missingTypeFields,
          [action.payload.type]: [
            ...state.missingTypeFields[action.payload.type] ?? [],
            ...action.payload.fields
          ]
        }
      }
    case UtilsActionTypes.TOGGLE_MESSAGES:
      return {
        ...state,
        areMessagesShown: action.payload,
        areNoticesShown: false
      }
    case UtilsActionTypes.TOGGLE_NOTICES:
      return {
        ...state,
        areNoticesShown: action.payload,
        areMessagesShown: false
      }
    case UtilsActionTypes.TOGGLE_FETCH_FILTERS_DATA:
      return {
        ...state,
        toggleFetchFiltersData: action.payload
      }
    case UtilsActionTypes.SIDEBAR_TABS_IS_OPEN:
      return {
        ...state,
        sidebarTabsIsOpen: action.payload
      }
    case UtilsActionTypes.SET_INSTANCE:
      return {
        ...state,
        instance: action.payload
      }
    case UtilsActionTypes.SET_JUST_DELETED_ORG:
      return {
        ...state,
        justDeletedOrgOguid: action.payload
      }
    case UtilsActionTypes.SET_LAST_LOCATION:
      return {
        ...state,
        lastLocation: action.payload
      }
    default:
      return state
  }
}

import { Canceler } from 'axios'
import { Action } from 'redux'
import { Instance } from '@const/consts'
import { Nullable } from '@store/types/commonTypes'

export enum UtilsActionTypes {
  SET_ASIDE_WIDTH = 'SET_ASIDE_WIDTH',
  RESET_ERROR404 = 'RESET_ERROR404',
  RESET_ERROR500 = 'RESET_ERROR500',
  SET_ERROR404 = 'SET_ERROR404',
  SET_ERROR500 = 'SET_ERROR500',
  SET_EXISTING_USER = 'SET_EXISTING_USER',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_MASSIVE_ACTION_PAGE = 'SET_MASSIVE_ACTION_PAGE',
  SET_MISSING_FIELDS = 'SET_MISSING_FIELDS',
  SET_MISSING_TYPE = 'SET_MISSING_TYPE',
  SET_MISSING_TYPE_FIELDS = 'SET_MISSING_TYPE_FIELDS',
  SET_THEME = 'SET_THEME',
  TOGGLE_BREADCRUMBS = 'TOGGLE_BREADCRUMBS',
  TOGGLE_MENUS = 'TOGGLE_MENUS',
  TOGGLE_NAV_MENU = 'TOGGLE_NAV_MENU',
  TOGGLE_ROOT_MODE = 'TOGGLE_ROOT_MODE',
  TOGGLE_MESSAGES = 'TOGGLE_MESSAGES',
  TOGGLE_NOTICES = 'TOGGLE_NOTICES',
  TOGGLE_FETCH_FILTERS_DATA = 'TOGGLE_FETCH_FILTERS_DATA',
  SIDEBAR_TABS_IS_OPEN = 'SIDEBAR_TABS_IS_OPEN',
  SET_INSTANCE = 'SET_INSTANCE',
  SET_JUST_DELETED_ORG = 'SET_JUST_DELETED_ORG',
  SET_LAST_LOCATION = 'SET_LAST_LOCATION'
}

export interface IActionSetAsideWidth extends Action<string> {
  payload: string
  type: UtilsActionTypes.SET_ASIDE_WIDTH
}

interface IActionSetError404 extends Action<string> {
  type: UtilsActionTypes.SET_ERROR404
}

export interface IActionResetError404 extends Action<string> {
  type: UtilsActionTypes.RESET_ERROR404
}

interface IActionSetError500 extends Action<string> {
  type: UtilsActionTypes.SET_ERROR500
}

export interface IActionResetError500 extends Action<string> {
  type: UtilsActionTypes.RESET_ERROR500
}

interface IActionToggleBreadcrumbs extends Action<string> {
  type: UtilsActionTypes.TOGGLE_BREADCRUMBS
}

export interface IActionSetLanguage extends Action<string> {
  payload: string
  type: UtilsActionTypes.SET_LANGUAGE
}

export interface IActionSetMassiveActionPage extends Action<string> {
  payload: AccessMassiveActionPage
  type: UtilsActionTypes.SET_MASSIVE_ACTION_PAGE
}

export interface IActionToggleMenus extends Action<string> {
  type: UtilsActionTypes.TOGGLE_MENUS
  payload: boolean
}

export interface IActionToggleNavMenu extends Action<string> {
  type: UtilsActionTypes.TOGGLE_NAV_MENU
  payload: boolean
}

export interface IActionToggleRootMode extends Action<string> {
  type: UtilsActionTypes.TOGGLE_ROOT_MODE
  payload: boolean
}

export interface IActionSetTheme extends Action<string> {
  payload: Themes
  type: UtilsActionTypes.SET_THEME
}

export interface IActionToggleMessages extends Action<string> {
  payload: boolean
  type: UtilsActionTypes.TOGGLE_MESSAGES
}

export interface IActionToggleNotices extends Action<string> {
  payload: boolean
  type: UtilsActionTypes.TOGGLE_NOTICES
}

export interface IActionSetExistingUser extends Action<string> {
  payload: string
  type: UtilsActionTypes.SET_EXISTING_USER
}

export interface IActionSetMissingFields extends Action<string> {
  payload: string[]
  type: UtilsActionTypes.SET_MISSING_FIELDS
}

export interface IActionSetMissingType extends Action<string> {
  payload: string
  type: UtilsActionTypes.SET_MISSING_TYPE
}

export interface IActionSetMissingTypeFields extends Action<string> {
  payload: IMissingTypeFields
  type: UtilsActionTypes.SET_MISSING_TYPE_FIELDS
}

export interface IActionToggleFetchFiltersData extends Action<string> {
  payload: boolean
  type: UtilsActionTypes.TOGGLE_FETCH_FILTERS_DATA
}

export interface IActionSidebarTabsIsOpen extends Action<string> {
  payload: boolean
  type: UtilsActionTypes.SIDEBAR_TABS_IS_OPEN
}

export interface IActionSetInstance extends Action<string> {
  payload: Instance
  type: UtilsActionTypes.SET_INSTANCE
}

export interface IActionSetJustDeletedOrg extends Action<string> {
  payload: Nullable<string>
  type: UtilsActionTypes.SET_JUST_DELETED_ORG
}

export interface IActionSetLastLocation extends Action<string> {
  payload: string
  type: UtilsActionTypes.SET_LAST_LOCATION
}

export type AccessMassiveActionPage = 'tasks' | 'messages' | 'documents' | 'receipts'

export type Themes = 'theme-auth' | 'theme-default'

export interface IMissingTypeFields {
  fields: string[]
  type: string
}

export type Actions =
  | IActionSetAsideWidth
  | IActionSetError404
  | IActionResetError404
  | IActionSetError500
  | IActionResetError500
  | IActionToggleBreadcrumbs
  | IActionToggleMenus
  | IActionToggleNavMenu
  | IActionToggleRootMode
  | IActionSetLanguage
  | IActionSetMassiveActionPage
  | IActionSetMissingFields
  | IActionSetMissingType
  | IActionSetMissingTypeFields
  | IActionSetTheme
  | IActionSetExistingUser
  | IActionToggleMessages
  | IActionToggleNotices
  | IActionToggleFetchFiltersData
  | IActionSidebarTabsIsOpen
  | IActionSetInstance
  | IActionSetJustDeletedOrg
  | IActionSetLastLocation

export interface IUtilsState {
  asideWidth: string
  existingUserOguid: string
  isBreadcrumbsShown: boolean
  isError404: boolean
  isError500: boolean
  isRootMode: boolean
  isShownMenus: boolean
  isShownNavMenu: boolean
  language: string
  massiveActionPage: AccessMassiveActionPage
  themeClass: Themes
  areMessagesShown: boolean
  areNoticesShown: boolean
  missingFields: string[]
  missingTypeFields: {
    [key: string]: string[]
  }
  missingTypes: string[]
  toggleFetchFiltersData: boolean
  sidebarTabsIsOpen: boolean
  instance: Instance
  justDeletedOrgOguid: Nullable<string>
  lastLocation: string
}

export interface ICanceler {
  canceler: Canceler
  id: string
}

import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'

import { createUrl } from '@utils/utils'

import { RouteName, Section } from '@const/consts'

import TokensService from '@services/tokensService';

import { AllRedirectToLoginProps as IProps } from '@containers/RedirectToStartPage/types'

const RedirectToStartPage: FC<IProps> = ({ alias, isRootMode, oguid, location: { pathname, search }, history }) => {
  const authorized = TokensService.checkAccessToken()
  const currentLocation = `${pathname}${search}`

  if (!authorized) {

    return (
      <Redirect
        to={{
          pathname: RouteName.SIGN_IN,
          state: { from: currentLocation }
        }}
      />
    )
  }

  const section = isRootMode ? Section.DOCUMENTS_IN_PROGRESS : Section.DOCUMENTS_ALL

  if (isRootMode) history.push(section)
  else if ((alias || oguid) && section) history.push(createUrl(alias || oguid, section))

  return null
}

export default RedirectToStartPage

import { AxiosResponse, AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import store from '@store/configureStore'

import urls from '@const/urls'
import { getClientToken } from '@utils/utils'

import {
  IEmailPasswordAuth,
  IFactorAuthConfirm,
  ILoginReset,
  IOrganization, IOrganizationDelete,
  IPhoneAuth,
  IPhoneAuthConfirm,
  IPhoneVerify,
  IProfile,
  IRequisites,
  ITokensFromAuth,
  IUpdatedProfile,
  IUserExtendedInfo
} from '@store/modules/user/types'

import {
  IExistingUserInvite,
  INewUser,
  INewUserInvite,
  IRepeatUserInvites,
  ISingleUser,
  ISingleUserInvite,
  IUpdatedUser,
  IUser,
  IUserInvite
} from '@store/modules/users/types'
import { INewOrganization, IParams } from '@store/types/commonTypes'
import { IRecipients, ISuggestRecipientWrapperParams } from '@common/Suggest/SuggestRecipientWrapper/types'
import { IUpdatedInviteUserValues } from '@views/administration/components/User/types'

class UsersService extends AbstractHttpService {
  private readonly url = urls.users

  // Request for user information by oguid
  // `${BASE_URL}orgs/${orgOguid}/users/${oguid}`
  async getUser<T = ISingleUser>(oguid: string = store.getState().user.profile.oguid, params?: IParams): Promise<AxiosResponse<T>> {
    const url = this.url.single.details.replace('{{ oguid }}', oguid)
    const config: AxiosRequestConfig = { params }
    const finalUrl = (url[url.length-1]==="/"?url.slice(0,-1):url)
    return await this._http.get(finalUrl, config)
  }

  // TODO - in order to be able to set the IUser[] type to the returned value, we need to
  // make fields email, role, etc. mandatory in the IData -> IUser interface for suggest
  // `${BASE_URL}orgs/${orgOguid}/users`
  async getUsers(config: AxiosRequestConfig): Promise<AxiosResponse> {
    const url = this.url.list

    return await this._http.get(url, config)
  }

  // `${BASE_URL}/user`
  async getProfile(): Promise<AxiosResponse<IProfile>> {
    const url = this.url.single.access

    return await this._http.get(url, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}/user`
  async updateProfile(profile: IUpdatedProfile): Promise<AxiosResponse> {
    const url = this.url.single.access

    return await this._http.put(url, profile, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}/user`
  async getExtendedInfo(): Promise<AxiosResponse<IUserExtendedInfo>> {
    const url = this.url.single.access

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/recipients`
  async getRecipients(params: ISuggestRecipientWrapperParams, orgOguid?: string, getOrgGuid?: boolean): Promise<AxiosResponse<IRecipients>> {
    let newOrgGuid = orgOguid;
    if (getOrgGuid) {
      newOrgGuid = store.getState().user.activeOrganization.oguid;
    }
    const url = this.url.recipients
    const config = { params }
    // this._http.get(url, config, !newOrgGuid, newOrgGuid)

    return await this._http.get(url, config, !newOrgGuid, newOrgGuid)
  }

  // `${BASE_URL}orgs/${orgOguid}/user/settings`
  async getUserSettings(): Promise<AxiosResponse<object>> {
    const url = this.url.single.settings

    return await this._http.get(url, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}/user/settings`
  async updateUserSettings(userSettings: object): Promise<AxiosResponse> {
    const url = this.url.single.settings

    return await this._http.put(url, userSettings, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}/users`
  async addUser(data: INewUser): Promise<AxiosResponse<IUser>> {
    const url = this.url.list

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/users/${userOguid}`
  async updateUser(userOguid: string, data: IUpdatedUser): Promise<AxiosResponse<IUser>> {
    const url = this.url.single.details.replace('{{ oguid }}', userOguid)

    return await this._http.put(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/users/${userOguid}`
  async deleteUser(userOguid: string): Promise<AxiosResponse> {
    const url = this.url.single.details.replace('{{ oguid }}', userOguid)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/users/invites`
  async getUserInvites(config: AxiosRequestConfig): Promise<AxiosResponse<IUserInvite[]>> {
    const url = this.url.invites.list

    return await this._http.get(url, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/users/invitesRepeat`
  async repeatUserInvites(data: IRepeatUserInvites): Promise<AxiosResponse> {
    const url = this.url.invites.repeat

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/users/invite`
  async sendNewUserInvite(data: INewUserInvite): Promise<AxiosResponse> {
    const url = this.url.invites.inviteNew

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/users/${userOguid}/invite`
  async sendExistingUserInvite(data: IExistingUserInvite, userOguid: string): Promise<AxiosResponse> {
    const url = this.url.invites.inviteExisting.replace('{{ userOguid }}', userOguid)

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/users/invites/${inviteOguid}`
  async deleteUserInvite(inviteOguid: string): Promise<AxiosResponse> {
    const url = this.url.invites.single.replace('{{ inviteOguid }}', inviteOguid)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/users/invites/${inviteOguid}`
  async getUserInvite(inviteOguid: string): Promise<AxiosResponse<ISingleUserInvite>> {
    const url = this.url.invites.single.replace('{{ inviteOguid }}', inviteOguid)

    return await this._http.get(url)
  }

  // `${BASE_URL}/orgs/{orgOguid}/users/invites/{inviteOguid}
  async updateInvite(inviteOguid: string, data: IUpdatedInviteUserValues): Promise<AxiosResponse> {
    const url = this.url.invites.single.replace('{{ inviteOguid }}', inviteOguid)

    return await this._http.put(url, data)
  }

  // `${BASE_URL}users`
  async getUserByLoginOrEmail(params: IParams): Promise<AxiosResponse<IUser>> {
    const url = this.url.list
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config, false)
  }

  // `${BASE_URL}user`
  async requestUserOrganizations(): Promise<AxiosResponse<IProfile>> {
    const url = this.url.single.access

    return await this._http.get(url, {}, false)
  }

  // `${BASE_URL}signup`
  async signup(payload: any): Promise<AxiosResponse> {
    const url = this.url.signUp

    return await this._http.post(url, payload, {}, false)
  }

  // `${BASE_URL}signin`
  async signin(data: IEmailPasswordAuth): Promise<AxiosResponse<ITokensFromAuth>> {
    const url = this.url.signIn

    const headers: RawAxiosRequestHeaders = {
      clientToken: getClientToken(),
      'Content-Type': 'application/json'
    }
    const config: AxiosRequestConfig = { headers }

    return await this._http.post(url, data, config, false)
  }

  // `${BASE_URL}phoneAuthInit`
  async phoneVerify(data: IPhoneAuth): Promise<AxiosResponse<IPhoneVerify>> {
    const url = this.url.phoneVerify

    return await this._http.post(url, data, {}, false)
  }

  // `${BASE_URL}phoneAuthConfirm`
  async phoneAuthConfirm(data: IPhoneAuthConfirm): Promise<AxiosResponse<ITokensFromAuth>> {
    const url = this.url.phoneAuthConfirm

    return await this._http.post(url, data, {}, false)
  }

  async SSOAuth(oguid: string): Promise<AxiosResponse<ITokensFromAuth>> {
    const url = this.url.SSOAuth

    const config: AxiosRequestConfig = {
      params: {
        oguid
      }
    }

    return await this._http.get(url, config, false)
  }

  // `${BASE_URL}phoneAuthConfirm`
  async factorAuthConfirm(data: IFactorAuthConfirm): Promise<AxiosResponse<ITokensFromAuth>> {
    const url = this.url.factorAuthConfirm

    return await this._http.post(url, data, {}, false)
  }

  // `${BASE_URL}password-reset`
  async resetPassword(login: ILoginReset): Promise<AxiosResponse> {
    const url = this.url.passwordReset

    return await this._http.post(url, login, {}, false)
  }

  // `${BASE_URL}orgs`
  async addNewOrganization(organizationData?: INewOrganization): Promise<AxiosResponse<IOrganization>> {
    const url = this.url.orgs.list

    return await this._http.post(url, organizationData, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}`
  async deleteOrganization(orgOguid: string): Promise<AxiosResponse<IOrganizationDelete>> {
    const url = this.url.orgs.single.replace('{{ orgOguid }}', orgOguid)

    return await this._http.delete(url, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}`
  async getRequisites(orgOguid: string): Promise<AxiosResponse<IOrganization>> {
    const url = this.url.orgs.single.replace('{{ orgOguid }}', orgOguid)

    return await this._http.get(url, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}`
  async updateRequisites(orgOguid: string, data: IRequisites): Promise<AxiosResponse<IOrganization>> {
    const url = this.url.orgs.single.replace('{{ orgOguid }}', orgOguid)

    return await this._http.put(url, data, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}/logo`
  async uploadLogoOrg(logo: string): Promise<AxiosResponse> {
    const url = this.url.logo
    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'text/plain'
    }
    const config: AxiosRequestConfig = { headers }

    return await this._http.post(url, logo, config)
  }

  // `${BASE_URL}orgs/${orgOguid}/logo`
  async getLogoOrg(): Promise<AxiosResponse<string>> {
    const url = this.url.logo

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/logo`
  async deleteLogoOrg(): Promise<AxiosResponse> {
    const url = this.url.logo

    return await this._http.delete(url)
  }

  // `{BASE_URL}generateAPIKey`
  async requestAPIKey(): Promise<AxiosResponse> {
    const url = this.url.generateAPIKey

    return await this._http.post(url, null, {}, false)
  }
}

export default new UsersService()

import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'

import {
  ApproveSecondFactor,
  FlowChangeNotification,
  UserCertStatus,
  UserRole,
  UserScope,
  UserStatus
} from '@const/consts'
import { IApplicationState } from '@store/types/commonTypes'
import { Nullable } from '@infologistics/frontend-libraries'
import { IAccessGroupDocTypeGroups, IAccessGroupDocTypes } from '@store/modules/metadata/types';

// common interface for all users
export interface IUser {
  accessibleGroups: IKeyData[]
  accessibleSubOrgs: IKeyData[]
  certificateExists: Nullable<IUserCertificateInfo>
  createdDate: Nullable<number>
  createdUser: Nullable<ICreatedUser>
  documentTypeAccessGroup: Nullable<IUserDocumentTypeAccessGroup>
  email: Nullable<string>
  lastActivity: Nullable<number>
  lastActivityIP: Nullable<string>
  lastLoginIp: Nullable<string>
  lastLogin: Nullable<number>
  login: Nullable<string>
  name: string
  oguid: string
  patronymic: Nullable<string>
  phone: Nullable<string>
  position: Nullable<string>
  role: UserRole
  scope: Nullable<UserScope>
  status: UserStatus
  subOrg: Nullable<IKeyData>
  surname: string
}

// user received when editing
export interface ISingleUser extends IUser {
  certificate: Nullable<IUserCertificate>
  docuforceCertificateGenerationData: Nullable<IDocuforceCertificateGenerationData>
  docuForceCertificateRequest: Nullable<IUserCertificateRequest>
  docuforceSignatureParam: Nullable<IDocuforceSignatureParam>
  documentTypeAccessGroupOguid: Nullable<string>
  extendedSigner: Nullable<IExtendedSigner>
  flowChangeNotification: FlowChangeNotification
  isNewMessageNotification: boolean
  isNewPatchNotification: boolean
  isNotificationEnabled: boolean
  isNotifyByEmail: boolean
  isNotifyByMobile: boolean
}

// user saved when editing
export interface IUpdatedUser {
  accessibleSubOrgOguids?: string[]
  documentTypeAccessGroupOguid: Nullable<string>
  extendedSigner?: IExtendedSigner
  flowChangeNotification: FlowChangeNotification
  isNewMessageNotification: boolean
  isNewPatchNotification: boolean
  isNotificationEnabled: boolean
  isNotifyByEmail: boolean
  isNotifyByMobile: boolean
  isDailyNotificationUnreadTask: boolean
  position: Nullable<string>
  role: UserRole
  scope?: UserScope
  status: UserStatus
  subOrgOguid?: string
}

// creating a new user
export interface INewUser extends IUpdatedUser {
  email: Nullable<string>
  login: Nullable<string>
  name: string
  password?: string
  patronymic?: string
  phone?: Nullable<string>
  sendInvite: boolean
  surname: string
  locale?: string
  timeZone?: string
}

export interface IUserInvite {
  accessibleSubOrgs: IKeyData[]
  additionalInfo1: Nullable<string>
  additionalInfo2: Nullable<string>
  createdDate: Nullable<number>
  createdUser: Nullable<ICreatedUser>
  documentTypeAccessGroup: Nullable<IUserDocumentTypeAccessGroup>
  email: string
  locale: string
  name: string
  notificationCount: number
  notificationDateTime: string
  oguid: Nullable<string>
  patronymic: string
  phone: string
  position: string
  role: string
  scope: Nullable<UserScope>
  status: Nullable<UserCertStatus>
  subOrg: Nullable<IKeyData>
  surname: string
  timezone: string
}

export interface IExistingUserInvite {
  accessibleSubOrgOguids: Nullable<string[]>
  additionalInfo1: Nullable<string>
  additionalInfo2: Nullable<string>
  docuforceCertificateGenerationData: IDocuforceCertificateGenerationData
  documentTypeAccessGroupOguid: Nullable<string>
  flowChangeNotification: FlowChangeNotification
  isDailyNotificationUnreadTask: boolean
  isNewMessageNotification: boolean
  isNewPatchNotification: boolean
  isNotificationEnabled: boolean
  isNotifyByEmail: boolean
  isNotifyByMobile: boolean
  issueCertificate: boolean
  role: UserRole
  position?: Nullable<string>,
  scope: Nullable<UserScope>
  subOrgOguid: Nullable<string>
}

export interface INewUserInvite {
  accessibleSubOrgOguids: Nullable<string[]>
  accessibleSubOrgs?: Nullable<string[]>
  additionalInfo1: Nullable<string>
  additionalInfo2: Nullable<string>
  docuforceCertificateGenerationData: IDocuforceCertificateGenerationData
  documentTypeAccessGroupOguid: Nullable<string>
  documentTypeAccessGroup?: Nullable<string>
  email?: Nullable<string>
  flowChangeNotification: FlowChangeNotification
  isDailyNotificationUnreadTask: boolean
  isNewMessageNotification: boolean
  isNewPatchNotification: boolean
  isNotificationEnabled: boolean
  isNotifyByEmail: boolean
  isNotifyByMobile: boolean
  issueCertificate: boolean
  locale: string
  name: string,
  role: UserRole
  patronymic?: Nullable<string>
  phone?: Nullable<string>
  position?: Nullable<string>,
  scope: Nullable<UserScope>
  subOrgOguid: Nullable<string>
  surname: string
  timeZone: string
}

export interface IRepeatUserInvites {
  invites: string[]
}

export interface ISingleUserInvite extends INewUserInvite {
  notificationCount?: number
  notificationDatetime?: string
  status?: Nullable<UserCertStatus>
}

export interface IUserCertificateInfo {
  isCertificateExist: boolean
  isCertificateExpired: boolean
}

export interface IUserDocumentTypeAccessGroup {
  oguid: string
  name: string
  documentTypes: IAccessGroupDocTypes[]
  documentTypeGroups: IAccessGroupDocTypeGroups[]
  hasDocumentTypes: boolean
}

export interface ICreatedUser {
  oguid: Nullable<string>
  surname: string
  name: string
  patronymic: Nullable<string>
  position: Nullable<string>
  email: Nullable<string>
}

export interface IKeyData {
  oguid: string
  name: string
}

export interface IExtendedSigner {
  signatureSignerInfo?: string
  signatureSignerOrgPowersBase?: string
  signatureSignerPowersBase?: string
  signatureSignerPowersBuyer: string
  signatureSignerPowersSeller: string
  signatureSignerStatus: string
  signatureSignerType: string
}

export interface IUserCertificate extends IDocuforceCertificateGenerationData {
  certificateBase64: string
  cn: string
  dateFrom: string
  dateTo: string
  oguid: string
  serialNumber: string
  standard: string
}

export interface IUserCertificateRequest {
  address: Nullable<string>
  confirmTries: number
  datetime: number
  inn: Nullable<string>
  oguid: string
  passport: Nullable<string>
  requestedBy: ICreatedUser
  snils: Nullable<string>
  status: UserCertStatus
}

export interface IDocuforceCertificateGenerationData {
  address: Nullable<string>
  inn: Nullable<string>
  passport: Nullable<string>
  snils: Nullable<string>
}

export interface IDocuforceSignatureParam {
  approveSecondFactor: ApproveSecondFactor
  isRequestBySms: boolean
}

export enum UsersActionTypes {
  RESET_USERS = 'RESET_USERS',
  RESET_USER_INVITES = 'RESET_USER_INVITES',
  SELECT_INVITE = 'SELECT_INVITE',
  SELECT_INVITES = 'SELECT_INVITES',
  SET_USERS = 'SET_USERS',
  SET_USER_INVITES = 'SET_USER_INVITES'
}

export interface IUsersState {
  invites: IUserInvite[]
  profiles: IUser[]
  selectedInvites: Record<string, boolean>
}

export interface IActionResetUsers extends Action<string> {
  type: UsersActionTypes.RESET_USERS
}

export interface IActionResetUserInvites extends Action<string> {
  type: UsersActionTypes.RESET_USER_INVITES
}

export interface IActionSelectInvite extends Action<string> {
  payload: string
  type: UsersActionTypes.SELECT_INVITE
}

export interface IActionSelectInvites extends Action<string> {
  payload: Record<string, boolean>
  type: UsersActionTypes.SELECT_INVITES
}

export interface IActionSetUsers extends Action<string> {
  payload: IUser[]
  type: UsersActionTypes.SET_USERS
}

export interface IActionSetUserInvites extends Action<string> {
  payload: IUserInvite[]
  type: UsersActionTypes.SET_USER_INVITES
}

export type Actions =
  IActionSetUsers |
  IActionResetUsers |
  IActionSelectInvite |
  IActionSelectInvites |
  IActionSetUserInvites |
  IActionResetUserInvites

type ActionKeys = 'setUsers' | 'setUserInvites'| 'selectInvite' | 'selectInvites'

export type UsersActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type UsersThunkAction = ThunkAction<Promise<AxiosResponse>, IApplicationState, void, Action<string>>

export type UsersThunkDispatch = ThunkDispatch<IUser[], void, Action<string>>

import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, Canceler } from 'axios'
import { sha256 } from 'js-sha256'

import i18n from '@app/i18n'
import cookies from '@utils/cookies'

import TokensService from '@services/tokensService'

import store from '@store/configureStore'

import {
  apiUrlCookieName,
  BASE_URL,
  ErrorCode,
  BrowserStorage,
  FILE_TOKEN_MIN_LENGTH,
  isDevelopment
} from '@const/consts'

import urls from '@const/urls'

import { ICanceler, UtilsActionTypes } from '@store/modules/utils/types'
import { Nullable } from '@store/types/commonTypes'

class Http {
  private isNeedResetError = {}
  private axiosEvents: Record<string, ICanceler> = {}
  private requestQueue: Set<string> = new Set()

  private readonly axiosInstance: AxiosInstance = axios.create({
    baseURL: cookies.get(apiUrlCookieName) ?? BASE_URL,
    responseType: 'json'
  })

  constructor() {
    this.addInterceptors()
  }

  private addInterceptors(): void {
    this.axiosInstance.interceptors.request.use((config) => {
      const controller = new AbortController()

      const requestConfigHash = this.getConfigHash(config)

      if (requestConfigHash && this.requestQueue.has(requestConfigHash)) {
        controller.abort()

        isDevelopment && window.console.warn(`Request to ${config.baseURL}${config.url} already in progress. Excessive request aborted`)
      }

      requestConfigHash && this.requestQueue.add(requestConfigHash)

      const { params, url } = config

      if (params?.isNeedResetError && url) {
        this.isNeedResetError[url] = true

        delete params.isNeedResetError
      }

      if (params?.requestId) {
        delete params.requestId
      }

      return {
        ...config,
        signal: controller.signal
      }
    })

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse<any, any> | Promise<AxiosResponse<any, any>> => {
        this.removeConfigHashFromQueue(response.config)

        // If
        // - the authorized user is different from the one saved in cookies
        // - or there is no user saved in cookies at all,
        // then clearing the authorization data and then displaying the authorization form
        const { oguid: user } = store.getState().user.profile
        const userFromService = TokensService.getUserFromCookies()

        if (!!user && (!userFromService || (userFromService && user !== userFromService))) {
          TokensService.clearCookies(user)

          document.location.reload()
        }

        const { url } = response.config

        if (url) {
          delete this.isNeedResetError[url]
        }

        return response
      },
      async (error: any) => {
        const {
          config,
          response
        } = error

        this.removeConfigHashFromQueue(config)

        const status = response?.status
        const originalRequest = config

        if (originalRequest) {
          const { url } = originalRequest

          if ((url.includes(urls.users.signIn) || url.includes(urls.users.passwordReset)) && status === ErrorCode.NOT_AUTH) {
            return await Promise.reject(error)
          }
        }

        if (status === ErrorCode.NOT_AUTH && originalRequest) {
          const { oguid: user } = store.getState().user.profile
          const userFromService = TokensService.getUserFromCookies()

          // If "Remember me" flag exists, then an attempt to update tokens based on the refresh token
          const isCurrentUser = userFromService && (!user || (!!user && user === userFromService))

          // If the authorized user matches the one stored in cookies,
          // then an attempt to update tokens based on the refresh token
          if (isCurrentUser) {
            const refreshToken = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_REFRESH, user)

            if (refreshToken) {
              originalRequest._retry = true
              const { url } = originalRequest

              await TokensService.memoized(refreshToken)

              // for requests to receive a document using fileToken
              // replace fileToken in url with updated one
              urls.documents.fileRequestsWithToken.forEach((request) => {
                if (url.includes(request)) {
                  const fileToken = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_FILE, user)
                  const regExp = new RegExp(`[\\w._-]{${FILE_TOKEN_MIN_LENGTH},}`)

                  originalRequest.url = originalRequest.url.replace(regExp, fileToken)
                }
              })

              originalRequest.headers['Access-Token'] = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_ACCESS, user)

              return await this.axiosInstance(originalRequest)
            }
          }

          // If the user does not match the saved one, then:
          // - clear cookies
          userFromService && TokensService.clearCookies(userFromService)

          // - display the authorization form on other tabs when performing any action with an API request
          document.location.reload()

          return await Promise.reject(error)
        }

        const { url } = originalRequest

        if (
          status === ErrorCode.NOT_FOUND &&
          !this.isNeedResetError[url] &&
          !url.includes('dicts') &&
          !url.includes('logo')
        ) {
          store.dispatch({ type: UtilsActionTypes.SET_ERROR404 })
        }

        delete this.isNeedResetError[url]

        return await Promise.reject(error)
      }
    )
  }

  private setHeaders(config: AxiosRequestConfig): void {
    const { headers = {} } = config

    const { oguid: user } = store.getState().user.profile
    const userFromService = TokensService.getUserFromCookies()

    const isCurrentUser = userFromService && (!user || (!!user && user === userFromService))

    const accessToken = TokensService.getTokenFromCookies(BrowserStorage.TOKEN_ACCESS, userFromService)

    if (isCurrentUser && accessToken) {
      headers['Access-Token'] = accessToken
    }

    config.headers = {
      ...headers,
      ['Accept-Language']: i18n.language
    }
  }

  private setCancelToken(config: AxiosRequestConfig, id: string): void {
    config.cancelToken = new axios.CancelToken((canceler: Canceler) => {
      this.axiosEvents[id] = { canceler, id }
    })
  }

  public removeAxiosEvent(id: string): void {
    const event = this.axiosEvents[id]
    event?.canceler()
    delete this.axiosEvents[id]
  }

  private updateBaseURL(config: AxiosRequestConfig, addOrgOguid: boolean, orgReplacementOguid?: string): void {
    const orgOguid = store.getState().user.activeOrganization.oguid
    const url = cookies.get(apiUrlCookieName) ?? BASE_URL

    config.baseURL = (orgReplacementOguid ?? addOrgOguid) ? `${url}orgs/${orgReplacementOguid ?? orgOguid}/` : `${url}`
  }

  async get(url: string, config: AxiosRequestConfig = {}, addOrgOguid = true, orgOguid?: string): Promise<any> {
    this.setHeaders(config)
    this.updateBaseURL(config, addOrgOguid, orgOguid)

    const requestId = config.params?.requestId
    requestId && this.setCancelToken(config, requestId)

    return await this.axiosInstance?.get(url, config).then((response) => {
      requestId && delete this.axiosEvents[requestId]

      return response
    })
  }

  async post(url: string, data: any, config: AxiosRequestConfig = {}, addOrgOguid = true, orgOguid?: string): Promise<any> {
    this.setHeaders(config)
    this.updateBaseURL(config, addOrgOguid, orgOguid)

    const requestId = config.params?.requestId
    requestId && this.setCancelToken(config, requestId)

    return await this.axiosInstance?.post(url, data, config).then((response) => {
      requestId && delete this.axiosEvents[requestId]

      return response
    })
  }

  async put(url: string, data: any, config: AxiosRequestConfig = {}, addOrgOguid = true): Promise<any> {
    this.setHeaders(config)
    this.updateBaseURL(config, addOrgOguid)

    const requestId = config.params?.requestId
    requestId && this.setCancelToken(config, requestId)

    return await this.axiosInstance?.put(url, data, config).then((response) => {
      requestId && delete this.axiosEvents[requestId]

      return response
    })
  }

  async delete(url: string, config: AxiosRequestConfig = {}, addOrgOguid = true, orgOguid?: string): Promise<any> {
    this.setHeaders(config)
    this.updateBaseURL(config, addOrgOguid, orgOguid)

    const requestId = config.params?.requestId
    requestId && this.setCancelToken(config, requestId)

    return await this.axiosInstance?.delete(url, config).then((response) => {
      requestId && delete this.axiosEvents[requestId]

      return response
    })
  }

  /** Hash functions */

  private getConfigHash(config: AxiosRequestConfig): Nullable<string> {
    const {
      baseURL,
      data,
      headers,
      method,
      url
    } = config

    // Limit repeated requests only for the GET method
    if (method?.toUpperCase() !== 'GET') return null
    // Exception for handbooks values requests
    if (url?.match(/dicts\/\w+\/values/)) return null

    const hashConfig = {
      baseURL,
      data,
      headers,
      method,
      url
    }

    const configString = JSON.stringify(hashConfig)

    return sha256(configString)
  }

  private removeConfigHashFromQueue(config: AxiosRequestConfig): void {
    const configHash = this.getConfigHash(config)

    configHash && this.requestQueue.delete(configHash)
  }
}

export default new Http()
